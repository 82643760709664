import Control from "../../../../Common/Controls/Control";

const BlockItemText = ({ block, onValueChange }) => {
  const handleChangeValue = (e) => {
    onValueChange({ ...block, text: e.target.value });
  };

  return (
    <div>
      <Control label="" type="textarea" value={block.text} change={handleChangeValue} />
    </div>
  );
};

export default BlockItemText;
