import { atom, useAtom } from "jotai";
import { toast } from "react-toastify";
import dataService from "../utils/dataService";

export const managerModel = {
  firstname: "",
  lastname: "",
  email: "",
  password: "",
};

const managersAtom = atom([]);
const isLoadingManagersAtom = atom(false);
const managersErrorsAtom = atom({});

const managerAtom = atom({
  firstname: "",
  lastname: "",
  email: "",
  password: "",
});
const isLoadingManagerAtom = atom(false);
const isSavingManagerAtom = atom(false);
const managerErrorsAtom = atom({});

const useManagers = () => {
  const [managers, setManagers] = useAtom(managersAtom);
  const [managersErrors, setManagersErrors] = useAtom(managersErrorsAtom);
  const [isLoadingManagers, setIsLoadingManagers] = useAtom(isLoadingManagersAtom);

  const [manager, setManager] = useAtom(managerAtom);
  const [isLoadingManager, setIsLoadingManager] = useAtom(isLoadingManagerAtom);
  const [isSavingManager, setIsSavingManager] = useAtom(isSavingManagerAtom);
  const [managerErrors, setManagerErrors] = useAtom(managerErrorsAtom);

  //DATA HANDLERS
  const getManagers = () => {
    setIsLoadingManagers(true);
    dataService.get({
      url: `user-managers`,
      onSuccess: setManagers,
      onError: setManagersErrors,
      onFinish: () => setIsLoadingManagers(false),
    });
  };

  const getManager = (managerId) => {
    setIsLoadingManager(true);
    dataService.get({
      url: `user-managers/${managerId}`,
      onSuccess: setManager,
      onError: setManagerErrors,
      onFinish: () => setIsLoadingManager(false),
    });
  };
  const postManager = (managerData) => {
    setIsSavingManager(true);
    dataService.post({
      url: `user-managers`,
      postData: managerData,
      onSuccess: (data) => {
        setManager(data);
        toast.success("L'administrateur a bien été créé");
      },
      onError: setManagerErrors,
      onFinish: () => setIsSavingManager(false),
    });
  };
  const patchManager = (managerData) => {
    setIsSavingManager(true);
    dataService.patch({
      url: `user-managers/${managerData.id}`,
      postData: managerData,
      onSuccess: (data) => {
        setManager(data);
        toast.success("L'administrateur a bien été modifié");
      },
      onError: setManagerErrors,
      onFinish: () => setIsSavingManager(false),
    });
  };
  const patchManagerPassword = (managerData) => {
    setIsSavingManager(true);
    dataService.patch({
      url: `user-managers/${managerData.id}/password`,
      postData: { password: managerData.password },
      onSuccess: (data) => {
        setManager(data);
        toast.success("Le mot de passe de l'administrateur a bien été modifié");
      },
      onError: setManagerErrors,
      onFinish: () => setIsSavingManager(false),
    });
  };
  const removeManager = (managerId) => {
    if (window.confirm("Confirmez-vous la suppression de cet administrateur ?")) {
      setIsSavingManager(true);
      dataService.remove({
        url: `user-managers/${managerId}`,
        onSuccess: (data) => {
          setManagers(managers.filter((manager) => manager.id != managerId));
          toast.success("L'administrateur a bien été supprimé");
        },
        onError: setManagerErrors,
        onFinish: () => setIsSavingManager(false),
      });
    }
  };

  //EVENTS HANDLERS

  const handleResetManager = () => {
    setManager({ ...managerModel });
    setManagerErrors({});
  };

  const handleChangeManagerData = (e) => {
    const { name, value } = e.target;
    setManager({ ...manager, [name]: value });
  };

  return {
    managers,
    managersErrors,
    isLoadingManagers,
    manager,
    managerErrors,
    isLoadingManager,
    isSavingManager,
    actions: {
      getManagers,
      getManager,
      postManager,
      patchManager,
      patchManagerPassword,
      removeManager,
      handleChangeManagerData,
      handleResetManager,
    },
  };
};

export default useManagers;
