import { atom, useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";
import dataService from "../utils/dataService";
import jwt_decode from "jwt-decode";

const authAtom = atom({
  isAuthenticated: false,
  user: {},
  isCheckingConnection: true,
});

const jwtTokenAtom = atomWithStorage("jwtToken", null);

const useAuth = () => {
  const [auth, setAuth] = useAtom(authAtom);
  const [jwtToken, setJwtToken] = useAtom(jwtTokenAtom);

  const updateJwtToken = (token) => {
    setJwtToken(token);
  };

  const checkUserConnected = () => {
    dataService.get({
      url: `auth/is-logged`,
      onSuccess: () => {
        setAuth({ ...auth, isAuthenticated: true, isCheckingConnection: false });
      },
      onError: () => {
        setAuth({ ...auth, isAuthenticated: false, isCheckingConnection: false });
      },
    });
  };

  const loginUser = ({ data, onSuccess, onError, onFinish }) => {
    dataService.post({
      url: `auth/login`,
      postData: data,
      onSuccess: (data) => {
        onSuccess(data);
        updateJwtToken(data.token);
        setAuth({
          ...auth,
          isAuthenticated: true,
          user: jwt_decode(data.token),
        });
      },
      onError,
      onFinish,
    });
  };

  const logoutUser = () => {
    dataService.get({
      url: `auth/logout`,
      onSuccess: () => {
        updateJwtToken(null);
        setAuth({ ...auth, isAuthenticated: false, user: {} });
      },
    });
  };

  return {
    auth,
    isAuth: auth.isAuthenticated,
    jwtToken,
    actions: {
      setAuth,
      loginUser,
      logoutUser,
      checkUserConnected,
      updateJwtToken,
    },
  };
};

export default useAuth;
