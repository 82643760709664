import React, { useState, useEffect } from "react";
import LabelContainer from "../LabelContainer";
import ButtonItem from "./ButtonItem";
import { BsFillPenFill } from "react-icons/bs";

const BtnListInput = (props) => {
  const { name, value, k, btnInline, datas, dataIndex, change } = props;
  const shouldStayOpen = datas.length < 6;
  const [isOpen, setIsOpen] = useState(!value || shouldStayOpen);
  const valueChange = (e, val, isExclusive) => {
    var filteredValue = val;

    let changeEvent = {
      target: {
        name: name,
        value: filteredValue,
        k: props.k,
      },
    };
    if (!shouldStayOpen) {
      setIsOpen(false);
    }
    change(changeEvent);
  };

  useEffect(() => {
    setIsOpen(value && !shouldStayOpen ? false : true);
  }, [value]);
  const orderedDatas = shouldStayOpen
    ? datas
    : datas.sort(
        (a, b) => {
          return a[dataIndex] == value ? -1 : 1;
        },
        { [dataIndex]: "" }
      );

  return (
    <LabelContainer {...props}>
      <div
        className={
          " d-flex flex-fill bg-light border " +
          (btnInline ? "d-flex flex-row flex-wrap " : "btn-group")
        }
      >
        {orderedDatas.map((data, dk) => {
          let isActive = data[dataIndex] == value;
          return (
            (isActive || shouldStayOpen) && (
              <ButtonItem
                key={"btnlist" + props.name + props.id + dk}
                {...props}
                isActive={isActive}
                data={data}
                dk={dk}
                valueChange={valueChange}
              />
            )
          );
        })}
        {isOpen &&
          !shouldStayOpen &&
          orderedDatas.map((data, dk) => {
            let isActive = data[dataIndex] == value;

            return (
              isOpen &&
              !isActive && (
                <ButtonItem
                  key={"btnlist" + props.name + props.id + dk}
                  {...props}
                  data={data}
                  dk={dk}
                  valueChange={valueChange}
                />
              )
            );
          })}
        {!isOpen && (
          <button
            className="btn btn-sm btn-outline-default border-0  mx-1"
            onClick={() => setIsOpen(true)}
          >
            <BsFillPenFill />
            <small style={{ position: "relative", top: 6, left: -2 }}>
              {datas.length - (value ? 1 : 0)}
            </small>
          </button>
        )}
        <button
          type="button"
          className="btn btn-sm btn-outline-default border-0  mx-1"
          onClick={(e) => valueChange(e, null)}
        >
          <i>vider</i>
        </button>
      </div>
    </LabelContainer>
  );
};

export default BtnListInput;
