import { useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import useAuth from "../../hooks/useAuth";

const useLoginPage = () => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { actions } = useAuth();
  const [user, setUser] = useState({
    login: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleReCaptchaVerify = async () => {
    setErrors({});
    if (!executeRecaptcha) {
      setErrors({ other: "Execute recaptcha not yet available" });
      return;
    }

    setIsLoading(true);
    const captchaToken = await executeRecaptcha("yourAction");

    actions.loginUser({
      data: { ...user, captchaToken },
      onSuccess: (apiToken) => {},
      onError: setErrors,
      onFinish: () => {
        setIsLoading(false);
      },
    });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;

    setUser({
      ...user,
      [name]: value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    handleReCaptchaVerify();
  };

  return {
    user,
    errors,
    isLoading,
    actions: {
      handleChangeInput,
      onSubmit,
    },
  };
};

export default useLoginPage;
