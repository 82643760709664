import React, { useContext } from "react";
import styled from "styled-components";
import useBlocks from "../../../hooks/useBlocks";
import { StepContext } from "../contexts/StepContext";
import Block from "./Block";
import StepTitle from "./StepTitle";

const ViewStep = () => {
  const { blocks } = useBlocks();
  const step = useContext(StepContext);

  return (
    <>
      <PreviewContainer className="shadow-lg mt-4 border-5 border-white p-3 px-4 border rounded mx-auto">
        <StepTitle>{step.title}</StepTitle>
        {blocks.map((block) => (
          <Block blockData={block} />
        ))}
      </PreviewContainer>
      <Backdrop />
    </>
  );
};

const PreviewContainer = styled.div`
  width: 420px;
  height: 720px;
  overflow-y: scroll;
  background: #450a56;
  color: white;
  position: absolute;
  z-index: 9;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: black;
`;

export default ViewStep;
