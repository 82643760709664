import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Fab from "../../Common/Fab/Fab";
import Page from "../../Common/Page/Page";
import useStep from "../../hooks/useStep";
import BlockList from "./BlockList/BlockList";
import { StepContext } from "./contexts/StepContext";
import StepForm from "./StepForm/StepForm";
import ViewStep from "./ViewStep/ViewStep";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const VisitDetailPage = () => {
  const params = useParams();
  const { step, actions } = useStep();
  const [mode, setMode] = useState("EDIT");

  useEffect(() => {
    actions.onGetStep(params.id);
  }, []);

  const pageTitle = step ? `#${step.order} ${step.title}` : "Étape";
  const toggleMode = () => {
    setMode(mode == "EDIT" ? "PREVIEW" : "EDIT");
  };

  return (
    <Page title={pageTitle} isLoading={!step} back={"/visit"}>
      <div className="row">
        <div className="col-12">
          <StepForm />
        </div>
        <div className="col-12">
          <StepContext.Provider value={step}>
            {mode == "EDIT" && <BlockList />}
            {mode == "PREVIEW" && <ViewStep />}
          </StepContext.Provider>
        </div>
      </div>
      <Fab style={{ bottom: 100 }}>
        <button className="btn btn-secondary rounded-circle" onClick={toggleMode}>
          {mode == "EDIT" && <AiFillEye />}
          {mode == "PREVIEW" && <AiFillEyeInvisible />}
        </button>
      </Fab>
    </Page>
  );
};

export default VisitDetailPage;
