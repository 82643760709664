import React, { useState } from "react";
import { patchStep } from "../../services/stepService";
import LoadButton from "../LoadButton/LoadButton";
import { tools } from "../../utils/tools";

const PublishStepButton = ({ step }) => {
  const [theStep, setTheStep] = useState(step);
  const [isPublishing, setIsPublishing] = useState(false);

  const togglePublishState = () => {
    setIsPublishing(true);
    patchStep({
      ...theStep,
      isPublished: !theStep.isPublished,
    })
      .then(setTheStep)
      .finally(() => setIsPublishing(false));
  };

  const btnColorCN = theStep.isPublished ? "text-primary" : "text-dark";
  const btnText = theStep.isPublished ? `Publié par ${theStep.publishedBy.firstname} le ${tools.getFullDate(theStep.publishedAt)}`: "Non publié";

  return (
    <LoadButton
      isLoading={isPublishing}
      onClick={togglePublishState}
      className={`btn btn-sm btn-link ${btnColorCN}`}
    >
      <div>
        <input
          className="form-check-input me-1"
          type="checkbox"
          value=""
          checked={theStep.isPublished ? "checked" : false}
        />
        <span>{btnText}</span>
      </div>
    </LoadButton>
  );
};

export default PublishStepButton;
