import Control from "../../../../Common/Controls/Control";
import FileUpload from "../../../../Common/FileInput/FileUpload";
import ImageSlider from "../../../../Common/ImageSlider/ImageSlider";
import React, { useState } from "react";
import { deleteFile, postFiles } from "../../../../services/fileService";

const BlockItemImage = ({ block, onValueChange }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeletingFileId, setIsDeletingFileId] = useState(false);

  const onFileChange = (newFiles) => {
    //upload file
    if (newFiles?.length > 0) {
      setIsUploading(true);
      postFiles(newFiles)
        .then((data) => {
          handleChangeValue({
            target: {
              name: "files",
              value: [{ ...data[0], legend: "" }],
            },
          });
        })
        .finally(() => setIsUploading(false));
    }
  };

  const onFileDelete = (fileId) => {
    setIsDeletingFileId(fileId);
    deleteFile(fileId)
      .then((data) => {
        handleChangeValue({
          target: {
            name: "files",
            value: block.files.filter((f) => f.id !== fileId),
          },
        });
      })
      .finally(() => setIsDeletingFileId(false));
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    onValueChange({ ...block, [name]: value });
  };

  const handleChangeLegend = (e, fileId) => {
    let newFile = block.files.find((f) => f.id == fileId);
    newFile = { ...newFile, legend: e.target.value };
    const newFiles = block.files.map((f) => (f.id == fileId ? newFile : f));
    handleChangeValue({ target: { name: "files", value: newFiles } });
  };

  return (
    <div>
      {block.files.length == 0 ? (
        <FileUpload onFileDrop={onFileChange} isSingleFile isLoading={isUploading} />
      ) : (
        <>
          <ImageSlider
            images={block.files}
            onChangeLegend={handleChangeLegend}
            onFileDelete={onFileDelete}
          />
        </>
      )}
    </div>
  );
};

export default BlockItemImage;
