import { BiSave } from "react-icons/bi";
import { AiOutlineCheck } from "react-icons/ai";
import Loader from "../../../../Common/Loader/Loader";
import styled from "styled-components";

const SaveIndicator = ({ isSaved, isSaving }) => {
  const getIndicatorContent = () => {
    if (isSaving) {
      return <Loader />;
    }
    if (isSaved) {
      return <AiOutlineCheck className="text-success" />;
    }
    return <BiSave className="text-warning" />;
  };

  return <StyledSaveIndicator className="d-center">{getIndicatorContent()}</StyledSaveIndicator>;
};

const StyledSaveIndicator = styled.div`
  background: white;
  border-radius: 50%;
  font-size: 19px;
`;

export default SaveIndicator;
