import React, { useState, useEffect } from "react";
import LabelContainer from "../LabelContainer";

const RangeInput = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  inputParams = {
    min: 0,
    max: 100,
    step: 1,
  },
  ...props
}) => {
  const getProgress = (value) => {
    let prog = (parseFloat(value) / inputParams.max) * 100;
    prog = prog > 94 ? 94 : prog < 3 ? 7 : prog;

    return value !== "" ? Math.round(prog) : 50;
  };
  const [progress, setProgress] = useState(getProgress(value));

  const valueChange = (e) => {
    var val = parseFloat(e.target.value);

    var filteredValue = val > inputParams.max ? inputParams.max : val;
    filteredValue = val < inputParams.min ? inputParams.min : filteredValue;
    filteredValue = isNaN(filteredValue) ? "" : filteredValue;
    setProgress(getProgress(filteredValue));
    let changeEvent = {
      target: {
        name: props.name,
        value: filteredValue,
        k: k,
      },
    };

    change(changeEvent);
  };

  useEffect(() => {
    setProgress(getProgress(value));
  }, [value]);

  return (
    <LabelContainer {...props} value={value}>
      <input
        min={inputParams.min}
        max={inputParams.max}
        step={inputParams.step}
        className={"form-control w-100 mb-3  custom-range " + inputClassName}
        type={type}
        disabled={disabled ? "disabled" : false}
        id={k != -1 ? props.name + "-" + k : id ? id : props.name}
        name={props.name}
        value={value}
        onChange={valueChange}
        placeholder={placeholder}
        style={{ ...inputStyle, marginTop: 40 }}
      />

      <input
        style={{
          width: 60,
          left: progress + "%",
          transform: "translate(-50%, 5px)",
          borderRadius: 3,
          transition: "0ms",
        }}
        type="text"
        pattern="[0-9]+([\.][0-9]+)?"
        className="form-control position-absolute range-inp-value text-center"
        onBlur={valueChange}
        name={props.name}
        defaultValue={value}
        disabled={disabled ? "disabled" : false}
        placeholder={placeholder || "_"}
      />
    </LabelContainer>
  );
};

export default RangeInput;
