import { useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Fab from "../../Common/Fab/Fab";
import Page from "../../Common/Page/Page";
import PublishStepButton from "../../Common/PublishStepButton/PublishStepButton";
import useStep from "../../hooks/useStep";
import useSteps from "../../hooks/useSteps";
import CreateStepForm from "./CreateStepForm";
import { BsGripVertical } from "react-icons/bs";
import { DragDropContext } from "react-beautiful-dnd";
import { Droppable } from "react-beautiful-dnd";
import { Draggable } from "react-beautiful-dnd";
import { SaveIndicatorContainer } from "../../Common/Styled/Styled";
import SaveIndicator from "../VisitDetailPage/BlockList/BlockItem/SaveIndicator";
import useConstants from "../../hooks/useConstants";

const GripHandle = ({ dragHandleProps }) => {
  return (
    <StyledDragHandle className="d-center text-white" {...dragHandleProps}>
      <BsGripVertical />
    </StyledDragHandle>
  );
};
const StyledDragHandle = styled.div`
  background: var(--grip-bg-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 30px;
  height: 100%;
  position: absolute;
  left: -30px;
  opacity: 0.5;
  transition: opacity 400ms;
  top: 0px;

  &:hover {
    opacity: 1;
  }
`;

const VisitPage = () => {
  const { steps, isLoadingSteps, isReorderingSteps, actions } = useSteps();
  const { actions: stepActions } = useStep();
  const { constants } = useConstants();

  useEffect(() => {
    actions.onGetSteps();
    stepActions.onResetStep();
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return false;
    }
    const items = Array.from(steps);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    actions.onReorderSteps(items);
  };

  return (
    <Page title="Visite guidée">
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="steps" type="STEPS">
          {(provided) => (
            <StyledTodoList
              className=""
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {steps.map((step, stepIndex) => {
                return (
                  <Draggable
                    key={`block${step.id}`}
                    draggableId={`block${step.id}`}
                    index={stepIndex}
                  >
                    {(provided) => (
                      <StyledBlockItem
                        className=" bg-white text-black p-0"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <GripHandle
                          dragHandleProps={provided.dragHandleProps}
                        />
                        <div className="w-100 d-flex align-items-center justify-content-between border-primary border rounded-end p-2">
                          <div>
                            <Link
                              to={`/visit/${step.id}`}
                              className="btn btn-link text-start d-flex flex-column btn-sm text-decoration-none fs-5"
                            >
                              #{stepIndex + 1} {step.title}{" "}
                              <small className="badge bg-secondary rounded align-self-baseline fw-normal">
                                {constants?.STEP_POSITIONS?.find(
                                  (pos) => pos.id == step.position
                                )?.title || "N.D"}
                              </small>
                            </Link>
                          </div>
                          <div>
                            <div className="btn-group">
                              <PublishStepButton step={step} />
                              <Link
                                to={`/visit/${step.id}`}
                                className="btn btn-primary btn-sm"
                              >
                                Ouvrir
                              </Link>
                              <button
                                onClick={() => actions.onDeleteStep(step.id)}
                                className="btn btn-danger btn-sm"
                              >
                                Supprimer
                              </button>
                            </div>
                          </div>
                        </div>
                      </StyledBlockItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </StyledTodoList>
          )}
        </Droppable>
      </DragDropContext>
      <div style={{ paddingTop: 200 }}></div>
      <Fab>
        <CreateStepForm />
      </Fab>
      <SaveIndicatorContainer>
        <SaveIndicator
          isSaved={!isReorderingSteps}
          isSaving={isReorderingSteps || isLoadingSteps}
        />
      </SaveIndicatorContainer>
    </Page>
  );
};

const StyledTodoList = styled.ul`
  margin-bottom: 10px;
  padding: 0px;
  list-style: none;
`;
const StyledBlockItem = styled.li`
  margin: 10px 0px;
  margin-bottom: 10px;
  padding: 10px;
  position: relative;
`;

export default VisitPage;
