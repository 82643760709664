import ImageSliderView from "../../../Common/ImageSliderView/ImageSliderView";

const ImagesBlock = ({ data }) => {
  return (
    <div className="w-100">
      <ImageSliderView images={data.files} />
    </div>
  );
};

export default ImagesBlock;
