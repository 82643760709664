import { useEffect, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import BlockAdd from "./BlockAdd/BlockAdd";
import BlockItem from "./BlockItem/BlockItem";
import styled from "styled-components";
import { BsGripVertical } from "react-icons/bs";
import useBlocks from "../../../hooks/useBlocks";
import { StepContext } from "../contexts/StepContext";
import SaveIndicator from "./BlockItem/SaveIndicator";
import { SaveIndicatorContainer } from "../../../Common/Styled/Styled";

const GripHandle = ({ dragHandleProps, disabled }) => {
  return (
    <StyledDragHandle
      className={`text-white d-center animate__animated animate__faster ${
        disabled ? "animate__fadeOutRight" : "animate__fadeInRight"
      }`}
      disabled={disabled}
      {...dragHandleProps}
    >
      <BsGripVertical />
    </StyledDragHandle>
  );
};
const StyledDragHandle = styled.div`
  background: var(--grip-bg-color);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  width: 30px;
  height: 100%;
  position: absolute;
  left: -30px;
  opacity: 0.5;
  transition: opacity 400ms;

  &:hover {
    opacity: 1;
  }
`;

const BlockList = () => {
  const { blocks, isSavingAllBlocks, isSavingBlocks, isAddingBlock, actions } = useBlocks();
  const step = useContext(StepContext);

  useEffect(() => {
    actions.onGetContentBlocks(step.id);
  }, []);

  const handleOnDragEnd = (result) => {
    if (!result.destination) {
      return false;
    }
    const items = Array.from(blocks);
    const [reorderedItems] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItems);
    actions.onupdateContentBlocks(step.id, items);
  };

  console.log(isSavingAllBlocks, isSavingBlocks);
  const disabledDragHandle = isSavingAllBlocks || isSavingBlocks.length > 0 || isAddingBlock;

  return (
    <StyledBlockList>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="blocks" type="BLOCKS">
          {(provided) => (
            <StyledTodoList {...provided.droppableProps} ref={provided.innerRef}>
              {blocks.map((blockData, blockIndex) => {
                return (
                  <Draggable
                    key={`block${blockData.id}`}
                    draggableId={`block${blockData.id}`}
                    index={blockIndex}
                  >
                    {(provided) => (
                      <StyledBlockItem ref={provided.innerRef} {...provided.draggableProps}>
                        <GripHandle
                          dragHandleProps={provided.dragHandleProps}
                          disabled={disabledDragHandle}
                        />
                        <BlockItem
                          blockData={blockData}
                          key={`block${blockData.id}`}
                          order={parseInt(blockIndex) + 1}
                        />
                      </StyledBlockItem>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </StyledTodoList>
          )}
        </Droppable>
      </DragDropContext>
      <BlockAdd />
      <SaveIndicatorContainer>
        <SaveIndicator isSaved={!isSavingAllBlocks} isSaving={isSavingAllBlocks} />
      </SaveIndicatorContainer>
    </StyledBlockList>
  );
};

const StyledBlockList = styled.div`
  padding-bottom: 100px;
  max-width: 970px;
  margin: 0 auto;
`;

const StyledTodoList = styled.ul`
  margin-bottom: 10px;
  padding: 0px;
  list-style: none;
`;
const StyledBlockItem = styled.li`
  margin: 10px 0px;
  margin-bottom: 28px;
  position: relative;
`;

export default BlockList;
