import React, { useRef } from "react";
import ControlValidIcon from "./ControlValidIcon";
import Validation from "./Validation";

const LabelContainer = ({
  labelClassname = "",
  k = -1,
  name,
  label = false,
  required = false,
  suffix = false,
  error = {},
  labelStyle = {},
  innerLabelStyle = {},
  innerLabelClassname = "",
  ...props
}) => {
  const labelRef = useRef(null);

  var compareValue = props.value === 0 ? props.value : props.value ? props.value : [];
  return (
    <label
      ref={labelRef}
      className={`form-label position-relative ${
        props.fullWidth ? "w-100" : ""
      } ${labelClassname} ${!props.isValid ? " has-error " : " "}
      ${props.type == "btnList" ? "w-auto" : "w-100"}
      ${error[name] ? " has-api-error " : " "}
        `}
      htmlFor={k != -1 ? name + "-" + k : name}
      style={labelStyle}
    >
      {label && (
        <span
          className={`label-text d-flex justify-content-start align-items-center ${innerLabelClassname}`}
          style={innerLabelStyle}
        >
          {label}
          {required && !Validation.required(compareValue) && (
            <span className="text-danger ml-1">*</span>
          )}
        </span>
      )}
      <div
        className={`position-relative ${props.fullWidth ? "w-100" : ""} ${
          props.inputGroupClassname
        } ${["btnList", "checkBtnList", "checkboxList"].includes(props.type) ? "" : ``}`}
        style={props.type == "checkBtnList" || props.inputGroupStyle ? props.inputGroupStyle : {}}
      >
        {props.children}
        {<ControlValidIcon hasError={!props.isValid} icon={props.validIcon} />}
        {suffix && <div className="input-group-append">{suffix}</div>}
      </div>
      {props.info && (
        <p className="text-white text-right mt-2 d-flex justify-content-end align-items-center">
          {props.infoIcon && <i className="fa fa-info-circle mr-2" />}
          {props.info}
        </p>
      )}
      {error[name] && (
        <small
          className={
            "form-text input-error-msg text-danger " + (error[name] ? "animated flash" : "")
          }
          style={{
            top: 6,
            right: 8,
            fontWeight: "bold",
            fontSize: 14,
          }}
        >
          {error[name]}
        </small>
      )}
    </label>
  );
};

export default LabelContainer;
