import Control from "../../Common/Controls/Control";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useManagers from "../../hooks/useManagers";

const ModifyPassword = () => {
  const { manager, managerErrors, actions, isSavingManager } = useManagers();

  const savePassword = () => {
    actions.patchManagerPassword(manager);
  };

  return (
    <div>
      <h5>Modification du mot de passe</h5>
      <Control
        type="password"
        label="Nouveau mot de passe"
        name="password"
        value={manager.password}
        change={actions.handleChangeManagerData}
        error={managerErrors}
      />
      <LoadButton isLoading={isSavingManager} className="btn btn-secondary" onClick={savePassword}>
        <span>Enregistrer le nouveau mot de passe</span>
      </LoadButton>
    </div>
  );
};

export default ModifyPassword;
