import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import styled from "styled-components";
import { IMAGE_URL } from "../../config";
import Img from "../Img/Img";

const ImageSliderView = ({ images }) => {
  return (
    <SwiperContainer>
      <Swiper
        modules={[Navigation, Pagination]}
        spaceBetween={10}
        slidesPerView={2}
        navigation={true}
        pagination={true}
        speed={1200}
        style={{ width: "100%" }}
      >
        {images.map((image, imageIndex) => {
          return (
            <SwiperSlide key={`swik${image.id}`} className="h-100 pb-4 pt-2">
              <SlideImageContainer className="w-100 d-center">
                <Img src={IMAGE_URL + image.fileName} />
              </SlideImageContainer>
              <p className="mt-2 text-center">
                <i>{image.legend}</i>
              </p>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </SwiperContainer>
  );
};

const SwiperContainer = styled.div`
  width: 100%;

  & .swiper-pagination-bullet {
    background: white;
  }

  & .swiper-button-prev,
  .swiper-button-next {
    color: white;
  }
`;

const SlideImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & img {
    max-width: 100%;
    max-height: 200px;
  }

  & .delete-image-btn {
    position: absolute;
    top: 10px;
    right: 10px;

    & .btn {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
`;

export default ImageSliderView;
