import { atom, useAtom } from "jotai";
import { getConstants } from "../services/constantService";

const constantsAtom = atom(null);

const useConstants = () => {
  const [constants, setConstants] = useAtom(constantsAtom);

  const onGetConstants = () => {
    getConstants().then(setConstants);
  };

  return {
    constants,
    actions: {
      onGetConstants,
    },
  };
};

export default useConstants;
