import dataService from "../utils/dataService";

export const postFiles = (files) => {
  return new Promise((resolve, reject) => {
    let data = new FormData();
    for (var i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }
    dataService.postFile({
      url: `files`,
      postData: data,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const patchFile = (file) => {
  return new Promise((resolve, reject) => {
    dataService.patch({
      url: `files`,
      postData: file,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const deleteFile = (fileId) => {
  return new Promise((resolve, reject) => {
    dataService.remove({
      url: `files/${fileId}`,
      postData: {},
      onSuccess: resolve,
      onError: reject,
    });
  });
};
