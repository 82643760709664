import dataService from "../utils/dataService";

export const getBlocks = (stepId) => {
  return new Promise((resolve, reject) => {
    dataService.get({
      url: `step-blocks/by-step/${stepId}`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const patchBlocksReorder = (stepId, newBlocks) => {
  return new Promise((resolve, reject) => {
    dataService.patch({
      url: `step-blocks/step/${stepId}/reorder`,
      postData: { stepBlocks: newBlocks.map((b) => b.id) },
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const deleteBlock = (blockId) => {
  return new Promise((resolve, reject) => {
    dataService.remove({
      url: `step-blocks/${blockId}`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const postBlock = (block) => {
  return new Promise((resolve, reject) => {
    dataService.post({ url: `step-blocks`, postData: block, onSuccess: resolve, onError: reject });
  });
};
export const patchBlock = (block) => {
  return new Promise((resolve, reject) => {
    dataService.patch({
      url: `step-blocks/${block.id}`,
      postData: block,
      onSuccess: resolve,
      onError: reject,
    });
  });
};
