import styled from "styled-components";
import AnecdoteBlock from "./AnecdoteBlock";
import ImageBlock from "./ImageBlock";
import ImagesBlock from "./ImagesBlock";
import QuizzBlock from "./QuizzBlock";
import TextBlock from "./TextBlock";
import VideoBlock from "./VideoBlock";

const componentsByType = {
  TEXT: TextBlock,
  ANECDOTE: AnecdoteBlock,
  IMAGE: ImageBlock,
  IMAGES: ImagesBlock,
  VIDEO: VideoBlock,
  QUIZZ: QuizzBlock,
};

const Block = ({ blockData }) => {
  const ComponentName = componentsByType[blockData.type];

  return (
    <BlockContainer className="w-100 py-3">
      <ComponentName data={blockData} />
    </BlockContainer>
  );
};

const BlockContainer = styled.div``;

export default Block;
