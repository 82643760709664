import react from "react";
import YouTube from "react-youtube";

function VideoPlayer({ videoId }) {
  const opts = {
    height: "390",
    width: "100%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 0,
      hl: "fr",
      iv_load_policy: 3,
      modestbranding: 1,
      showinfo: 0,
      controls: 1,
    },
  };
  return !videoId ? null : (
    <YouTube
      videoId={videoId} // defaults -> ''
      opts={opts}
    />
  );
}

const MemoedVideoPlayer = react.memo(VideoPlayer);

export default MemoedVideoPlayer;
