import dataService from "../utils/dataService";

export const getSteps = () => {
  return new Promise((resolve, reject) => {
    dataService.get({
      url: `steps/types/1`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const patchReorderSteps = (newSteps) => {
  return new Promise((resolve, reject) => {
    dataService.patch({
      url: `steps/reorder/1`,
      postData: { steps: newSteps.map((s) => s.id) },
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const getStep = (stepId) => {
  return new Promise((resolve, reject) => {
    dataService.get({
      url: `steps/${stepId}`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const deleteStep = (stepId) => {
  return new Promise((resolve, reject) => {
    dataService.remove({
      url: `steps/${stepId}`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};

export const postStep = (step) => {
  return new Promise((resolve, reject) => {
    dataService.post({ url: `steps`, postData: step, onSuccess: resolve, onError: reject });
  });
};

export const patchStep = (step) => {
  return new Promise((resolve, reject) => {
    dataService.patch({
      url: `steps/${step.id}`,
      postData: step,
      onSuccess: resolve,
      onError: reject,
    });
  });
};
