import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { REACAPTCHA_KEY } from "../../config";
import useAuth from "../../hooks/useAuth";
import LoginPage from "../../Pages/LoginPage/LoginPage";
import Loader from "../Loader/Loader";

const PrivateRoute = ({ children }) => {
  const { auth } = useAuth();

  if (auth.isCheckingConnection) {
    return (
      <div className="d-center w-100 h-100">
        <Loader />
      </div>
    );
  }

  if (!auth.isAuthenticated) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey={REACAPTCHA_KEY}>
        <LoginPage />
      </GoogleReCaptchaProvider>
    );
  }

  return children;
};

export default PrivateRoute;
