import React, { useState, useEffect } from "react";
import { BsCloudDownload } from "react-icons/bs";
import QRCode from "react-qr-code";
import styled from "styled-components";
import Container from "../../Common/Container/Container";
import Control from "../../Common/Controls/Control";
import Page from "../../Common/Page/Page";
import { APP_URL } from "../../config";
import useSteps from "../../hooks/useSteps";
import { downloadSVG } from "./downloadSVG";

const QRCodePage = () => {
  const { steps, actions } = useSteps();
  const [selectedSteps, setSelectedSteps] = useState(steps.map((s) => s.id));

  useEffect(() => {
    actions.onGetSteps();
  }, []);

  useEffect(() => {
    setSelectedSteps(steps.map((s) => s.id));
  }, [steps]);

  const handleChangeSelectedSteps = (e) => {
    setSelectedSteps(e.target.value);
  };

  return (
    <Page title="QR codes des étapes visite guidée">
      <Container>
        <Control
          label="Etapes affichées"
          type="checkBtnList"
          datas={steps}
          dataIndex="id"
          dataLabel="title"
          value={selectedSteps}
          change={handleChangeSelectedSteps}
          btnInline
          btnSm
        />
      </Container>
      {steps
        .filter((s) => selectedSteps.includes(s.id))
        .map((step) => {
          const stepUrl = `${APP_URL}visite-guidee/etape/${step.slugName}`;
          const stepId = `qrcode-${step.id}`;
          return (
            <StyledQrCodeContainer className="bg-white mx-auto my-2 p-3 d-center flex-column">
              <QRCode value={stepUrl} id={stepId} />
              <div className="my-2">{step.title}</div>
              <div className="my-2">{stepUrl}</div>
              <div className="my-2">
                <a
                  className="btn btn-info"
                  href={downloadSVG(stepId)}
                  download={`${step.title}-qrcode.svg`}
                >
                  <BsCloudDownload />
                  <span className="ms-2">télécharger au format vectoriel</span>
                </a>
              </div>
            </StyledQrCodeContainer>
          );
        })}
    </Page>
  );
};

const StyledQrCodeContainer = styled.div``;

export default QRCodePage;
