import React, { useRef } from "react";

const ButtonItem = ({
  k = -1,
  autoFocus,
  autocomplete = "on",
  inputStyle = {},
  inputClassName = "",
  type,
  disabled = false,
  id = null,
  value,
  change = () => {},
  placeholder = "",
  data,
  dk, //key of button element
  dataIndex = "id",
  dataLabel = "name",
  dataLabelIndex = false, //si le label est dans un objet ex : { label: { name: "Label"} }
  dataLabel2 = false,
  dataIcon = false,
  btnInline = false,
  itemClassName = "",
  itemStyle = {},
  isActive,
  valueChange,
  btnClassName = null,
  name,
}) => {
  const btnRef = useRef(null);

  return (
    <button
      ref={btnRef}
      className={`
            btn  
            position-relative 
            flex-row align-items-center flex-fill justify-content-between mb-0 vivify flipInX 
            ${itemClassName} 
            ${
              btnInline
                ? "btn-sm text-center mr-0  ml-1 flex-column justify-content-center flex-wrap "
                : " "
            }
              ${
                isActive
                  ? btnInline
                    ? btnClassName || "btn-primary "
                    : btnClassName || "btn-primary  "
                  : "btn-light"
              }
            `}
      style={{
        ...itemStyle,
        animationDelay: `${dk * 30}ms`,
      }}
      disabled={disabled ? "disabled" : false}
      type="button"
      key={`${name} - ${data.id ? data.id : data._id ? data._id + dk : "abcd" + dk} `}
      onClick={(e) => {
        valueChange(e, data[dataIndex], data.isExclusive);
      }}
    >
      {dataIcon && (
        <div
          className={`d-flex align-items-center justify-content-center p-2 ${
            !btnInline ? "mr-2" : ""
          }`}
        >
          <i
            className={"illustration fa fa-" + data[dataIcon] + ""}
            style={{
              background: "transparent",
              fontSize: 26,
              padding: 0,
              width: 26,
            }}
          />
        </div>
      )}
      <p className={`d-flex flex-column mb-0 font-weight-bold flex-fill`}>
        {dataLabelIndex ? data[dataLabel][dataLabelIndex] : data[dataLabel]}

        {data.dataLabel2 && (
          <span className={`mt-1 `} style={{ fontSize: 11, lineHeight: "12px" }}>
            {data.dataLabel2}
          </span>
        )}
      </p>

      <i
        className={`check-icon fa fa-check  animated ${
          isActive ? "fadeInDown" : "fadeOutUp"
        } faster 
          ${btnInline ? "position-absolute text-white bg-content shadow-sm " : ""}
                  `}
        style={
          btnInline
            ? {
                top: -6,
                right: -6,
                fontSize: 8,
                padding: "3px",
                borderRadius: "100%",
              }
            : {}
        }
      />
    </button>
  );
};

export default ButtonItem;
