import styled from "styled-components";

const ActionButtonsContainer = ({ children }) => {
  return <StyledActionButtonsContainer>{children}</StyledActionButtonsContainer>;
};

const StyledActionButtonsContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
`;

export default ActionButtonsContainer;
