import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import { postStep } from "../../services/stepService";
import { stepModel } from "../../hooks/useStep";
import { useNavigate } from "react-router-dom";
import Control from "../../Common/Controls/Control";
import LoadButton from "../../Common/LoadButton/LoadButton";
import ErrorsDisplay from "../../Common/ErrorsDisplay/ErrorsDisplay";

const CreateStepForm = () => {
  const [slugName, setSlugName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [errors, setErrors] = useState({});

  const onChangeSlugName = (e) => {
    setSlugName(e.target.value.replace(/[^a-zA-Z]-/g, ""));
  };

  let navigate = useNavigate();
  const onCreateStep = () => {
    setIsCreating(true);
    setErrors({});
    postStep({ ...stepModel, slugName })
      .then((data) => navigate(`/visit/${data.id}`))
      .catch(setErrors)
      .finally(() => setIsCreating(false));
  };

  return (
    <div className="p-3 bg-white shadow-sm">
      <h5>Créer une nouvelle étape</h5>
      <Control
        label="Lien de l'étape (ne pourra plus être modifié)"
        type="text"
        name="slugName"
        value={slugName}
        change={onChangeSlugName}
        error={errors}
      />
      <small>www.lazaret-grandechaloupe.re/step/{slugName}</small>
      <ErrorsDisplay errorsObject={errors} excludedKeys={["slugName"]} />
      {
        <LoadButton
          onClick={onCreateStep}
          className="btn btn-primary rounded-circle"
          isLoading={isCreating}
        >
          <AiOutlinePlus />
        </LoadButton>
      }
    </div>
  );
};

export default CreateStepForm;
