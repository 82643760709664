import React, { useState } from "react";

const Tip = ({ tip }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openTip = () => {
    setIsOpen(true);
  };
  const closeTip = () => {
    setIsOpen(false);
  };

  return (
    <div
      style={{
        position: "absolute",
        right: 5,
        top: 5,
        minWidth: 180,
        zIndex: "89",
      }}
    >
      {isOpen && (
        <div
          className="position-absolute bg-light  rounded"
          style={{
            right: 0,
            bottom: 0,
            padding: 10,
          }}
        >
          {tip}
        </div>
      )}
      <button
        className="btn p-0 bg-trans border-0 position-absolute"
        style={{
          right: 0,
          top: 0,
        }}
        onMouseEnter={openTip}
        onMouseLeave={closeTip}
      >
        <i className="fa fa-question-circle text-white" />
      </button>
    </div>
  );
};

export default Tip;
