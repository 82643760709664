import { useState } from "react";
import ActionButtonsContainer from "../../../Common/ActionButtonsContainer/ActionButtonsContainer";
import Container from "../../../Common/Container/Container";
import Control from "../../../Common/Controls/Control";
import LoadButton from "../../../Common/LoadButton/LoadButton";
import useConstants from "../../../hooks/useConstants";
import useStep from "../../../hooks/useStep";
import SaveIndicator from "../BlockList/BlockItem/SaveIndicator";

var saveTimeout = null;

const StepForm = () => {
  const { step, isStepSaving, actions } = useStep();
  const { constants } = useConstants();
  const [data, setData] = useState(step);
  const [hasChanged, setHasChanged] = useState(false);

  const handleChangeStep = (e) => {
    const { name, value } = e.target;
    const newStep = { ...data, [name]: value };
    setData(newStep);
    setHasChanged(true);
    save(newStep);
  };

  const save = (newStep) => {
    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      console.log("SAVING STEP");
      setHasChanged(false);
      actions.onPatchStep(newStep);
    }, 3000);
  };

  return (
    <Container className="position-relative mb-3">
      <ActionButtonsContainer>
        <SaveIndicator isSaved={!hasChanged} isSaving={isStepSaving} />
      </ActionButtonsContainer>
      <Control
        label="Titre de l'étape"
        type="text"
        name="title"
        value={data.title}
        change={handleChangeStep}
      />
      <Control
        label="Position de l'étape"
        type="btnList"
        name="position"
        value={data.position}
        datas={constants.STEP_POSITIONS}
        dataIndex="id"
        dataLabel="title"
        change={handleChangeStep}
      />
    </Container>
  );
};

export default StepForm;
