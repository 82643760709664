import { useContext } from "react";
import useBlocks, { blockTypes } from "../../../../hooks/useBlocks";
import { AiOutlinePlus } from "react-icons/ai";
import styled from "styled-components";
import { StepContext } from "../../contexts/StepContext";

const AddBlockButton = ({ type, onClick, children, ...props }) => {
  return (
    <button
      className={`btn btn-primary    shadow d-center`}
      onClick={() => onClick({ type })}
      {...props}
    >
      {children}
    </button>
  );
};

const BlockAdd = () => {
  const step = useContext(StepContext);
  const { actions, isAddingBlock } = useBlocks();

  const handleAddBlockClick = ({ type }) => {
    const newblock = { ...blockTypes.find((b) => b.type == type), stepId: step.id };
    actions.onAddContentBlock(newblock);
  };

  return (
    <StyledBlockAdd className="text-center animate__animated animate__fadeInUp animate__delay-1s my-3">
      <div className="d-center btn-group mb-3 rounded-pill overflow-hidden mx-4 shadow-lg border-5 border border-white">
        {blockTypes.map(({ type, name }) => (
          <AddBlockButton
            type={type}
            onClick={handleAddBlockClick}
            key={`blockadd${type}`}
            disabled={isAddingBlock ? "disabled" : ""}
          >
            <AiOutlinePlus />
            {name}
          </AddBlockButton>
        ))}
      </div>
    </StyledBlockAdd>
  );
};

const StyledBlockAdd = styled.div`
  position: fixed;
  bottom: 0;
  z-index: 99;
  width: calc(100% - 280px);
  left: 280px;
`;

export default BlockAdd;
