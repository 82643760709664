import { BrowserRouter } from "react-router-dom";
import Navbar from "./Common/Navbar/Navbar";
import styled from "styled-components";
import { routesConfig } from "./routesConfig";
import { ToastContainer } from "react-toastify";
import "./axiosConfig.js";
import AppRoutes from "./AppRoutes";
import ContentWrapper from "./Common/ContentWrapper/ContentWrapper";
import useAuth from "./hooks/useAuth";
import { useEffect } from "react";
import useConstants from "./hooks/useConstants";

function App() {
  const { actions: constantActions } = useConstants();
  const { actions } = useAuth();
  useEffect(() => {
    actions.checkUserConnected();
    constantActions.onGetConstants();
  }, []);

  return (
    <StyledAppWrapper>
      <BrowserRouter>
        <Navbar routes={routesConfig} />
        <ContentWrapper>
          <AppRoutes />
        </ContentWrapper>
      </BrowserRouter>
      <ToastContainer />
    </StyledAppWrapper>
  );
}

const StyledAppWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: row;
`;

export default App;
