import styled from "styled-components";

const TextBlock = ({ data }) => {
  return <StyledP>{data.text}</StyledP>;
};

const StyledP = styled.p`
  white-space: pre-line;
`;

export default TextBlock;
