import React from "react";
import Loader from "../Loader/Loader";

const Table = ({
  datas,
  fields,
  isLoading,
  nbResults = null,
  lineBackgroundFromAttribute = false,
  lineColor = false,
}) => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-end">
        <div className="badge text-secondary" style={{ fontSize: 12 }}>
          {nbResults !== null ? (
            <>
              {isLoading ? (
                <Loader />
              ) : (
                <>
                  {nbResults} résultat{nbResults > 0 ? "s" : ""}
                </>
              )}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div
        className={`pt-0 w-100`}
        style={{
          overflowY: "auto",
        }}
      >
        <table className="table table-hover mb-0">
          <thead>
            <tr>
              {fields.map((h, hk) => (
                <th
                  className={`px-2 ${h.headerClassname || ""}`}
                  key={"th" + hk + h.name}
                  style={{
                    textAlign: hk == fields.length - 1 ? "right" : "left",
                  }}
                >
                  {h.name}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="border-top-0">
            {!datas?.length ? (
              <tr className="animated fadeInUp faster">
                {fields.map((f, fk) => {
                  return <td key={`emptfield${fk}${f.name}`}>-</td>;
                })}
              </tr>
            ) : (
              datas.map((d, dk) => (
                <tr
                  key={"tbrow" + dk}
                  className="animated fadeInUp faster"
                  style={{
                    animationDelay: dk * 30 + "ms",
                  }}
                >
                  {fields.map((f, fk) => (
                    <td
                      style={{
                        verticalAlign: "middle",
                        background: lineBackgroundFromAttribute
                          ? d[lineBackgroundFromAttribute]
                          : "",
                        color: lineColor || "",
                        textAlign: fk == fields.length - 1 ? "right" : "left",
                      }}
                      key={"tbcol" + dk + "" + fk}
                    >
                      {f.render ? f.render(f.path == "*" ? d : d[f.path], dk) : d[f.path]}
                    </td>
                  ))}
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
