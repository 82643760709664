import { useState } from "react";
import styled from "styled-components";
import useBlocks from "../../../../hooks/useBlocks";
import BlockItemAnecdote from "../BlockItemAnecdote/BlockItemAnecdote";
import BlockItemImage from "../BlockItemImage/BlockItemImage";
import BlockItemImages from "../BlockItemImages/BlockItemImages";
import BlockItemQuizz from "../BlockItemQuizz/BlockItemQuizz";
import BlockItemText from "../BlockItemText/BlockItemText";
import BlockItemVideo from "../BlockItemVideo/BlockItemVideo";
import SaveIndicator from "./SaveIndicator";
import { FaRegTrashAlt } from "react-icons/fa";
import BlockItemTitle from "./BlockItemTitle";
import { tools } from "../../../../utils/tools";
import ActionButtonsContainer from "../../../../Common/ActionButtonsContainer/ActionButtonsContainer";

const componentsByType = {
  TEXT: BlockItemText,
  ANECDOTE: BlockItemAnecdote,
  IMAGE: BlockItemImage,
  IMAGES: BlockItemImages,
  VIDEO: BlockItemVideo,
  QUIZZ: BlockItemQuizz,
};

let saveTimeout = null;

const BlockItem = ({ blockData, order }) => {
  const { actions, isSavingBlocks } = useBlocks();
  const ComponentName = componentsByType[blockData.type];
  const [data, setData] = useState(blockData);
  const [hasChanged, setHasChanged] = useState(false);

  const onValueChange = (newValue) => {
    const newBlock = { ...data, ...newValue };
    setData(newBlock);
    setHasChanged(true);
    save(newBlock);
  };

  const save = (newBlock) => {
    clearTimeout(saveTimeout);
    saveTimeout = setTimeout(() => {
      console.log("SAVING");
      setHasChanged(false);
      actions.onUpdateContentBlock(newBlock);
    }, 3000);
  };

  const onDeleteBlock = () => {
    actions.onRemoveContentBlock(data);
  };

  const isSaving = isSavingBlocks.includes(data.id);

  return (
    <StyledBLockItem className="p-3 rounded-end bg-white  border border-primary shadow-sm">
      <ActionButtonsContainer>
        <SaveIndicator isSaved={!hasChanged} isSaving={isSaving} />
        <button className="btn btn-link text-dark" onClick={onDeleteBlock}>
          <FaRegTrashAlt />
        </button>
      </ActionButtonsContainer>
      <BlockItemTitle
        title={
          <>
            <small>#{order}</small> {tools.getBlockNameFromType(data.type)}
          </>
        }
      />
      <ComponentName block={data} onValueChange={onValueChange} />
    </StyledBLockItem>
  );
};

const StyledBLockItem = styled.div`
  position: relative;
`;

export default BlockItem;
