import { atom, useAtom } from "jotai";
import { getStep, patchStep } from "../services/stepService";

export const stepModel = {
  isPublished: false,
  slugName: "",
  type: "1",
  title: "Titre de l'étape",
  position: "",
};

const stepAtom = atom(null);

const isStepSavingAtom = atom(false);

const useStep = () => {
  const [step, setStep] = useAtom(stepAtom);
  const [isStepSaving, setIsStepSaving] = useAtom(isStepSavingAtom);

  console.log("STEP", step);

  const onResetStep = () => {
    setStep(null);
  };

  const onGetStep = (stepId) => {
    getStep(stepId).then(onInitStep);
  };

  const onInitStep = (initialStepData) => {
    setStep(initialStepData);
  };

  const onUpdateStep = (newStep) => {
    setStep(newStep);
  };

  const onPatchStep = (newStep) => {
    setIsStepSaving(true);
    patchStep(newStep)
      .then(setStep)
      .finally(() => setIsStepSaving(false));
  };

  console.log("step", step);

  return {
    step,
    isStepSaving,
    actions: {
      onGetStep,
      onInitStep,
      onUpdateStep,
      onPatchStep,
      onResetStep,
    },
  };
};

export default useStep;
