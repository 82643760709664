import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper";
import styled from "styled-components";
import Control from "../Controls/Control";
import { IMAGE_URL } from "../../config";
import { AiOutlineClose, AiOutlineArrowLeft, AiOutlineArrowRight } from "react-icons/ai";
import Loader from "../Loader/Loader";

const ImageSlider = ({
  images,
  onChangeLegend,
  onFileDelete,
  onFileChangeOrder,
  isDeletingFileId,
}) => {
  const setImageToPreviousSlot = (imageIndex) => {
    if (imageIndex > 0) {
      onFileChangeOrder(imageIndex, imageIndex - 1);
    }
  };
  const setImageToNextSlot = (imageIndex) => {
    if (imageIndex < images.length - 1) {
      onFileChangeOrder(imageIndex, imageIndex + 1);
    }
  };

  return (
    <Swiper
      modules={[Navigation, Pagination]}
      spaceBetween={20}
      slidesPerView={3}
      navigation={true}
      pagination={true}
      speed={1200}
      style={{ width: "100%" }}
    >
      {images.map((image, imageIndex) => {
        return (
          <SwiperSlide key={`swik${image.id}`} className="h-100 pb-4 pt-2">
            <SlideImageContainer className="d-center flex-column border rounded bg-light pb-2 my-2">
              <img src={IMAGE_URL + image.fileName} />
              <div className="w-100 d-center ">
                <div>
                  {/* {imageIndex > 0 && (
                    <button
                      className="btn btn-light"
                      onClick={() => setImageToPreviousSlot(imageIndex)}
                    >
                      <AiOutlineArrowLeft />
                    </button>
                  )} */}
                </div>
                <Control
                  label="Légende"
                  type="text"
                  name="legend"
                  value={image.legend}
                  change={(e) => onChangeLegend(e, image.id)}
                  fullWidth
                  className="px-2"
                />
                <div>
                  {/* {images.length > 0 && imageIndex < images.length - 1 && (
                    <button
                      className="btn btn-light"
                      onClick={() => setImageToNextSlot(imageIndex)}
                    >
                      <AiOutlineArrowRight />
                    </button>
                  )} */}
                </div>
              </div>

              <div className="delete-image-btn">
                {isDeletingFileId == image.id ? (
                  <Loader />
                ) : (
                  <button
                    onClick={() => onFileDelete(image.id)}
                    className="btn btn-sm bg-white delete-image-btn rounded border  p-0 d-center text-danger shadow-sm"
                    title="supprimer l'image"
                  >
                    <AiOutlineClose />
                  </button>
                )}
              </div>
            </SlideImageContainer>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

const SlideImageContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  & img {
    max-width: 100%;
    max-height: 200px;
  }

  & .delete-image-btn {
    position: absolute;
    top: 0px;
    right: 0px;

    & .btn {
      width: 30px;
      height: 30px;
      font-size: 14px;
    }
  }
`;

export default ImageSlider;
