import MemoedVideoPlayer from "../../../Common/VideoPlayer/VideoPlayer";

const VideoBlock = ({ data }) => {
  return (
    <div className="w-100 border border-5 rounded">
      <MemoedVideoPlayer videoId={data.videoId} />
    </div>
  );
};

export default VideoBlock;
