import { atom, useAtom } from "jotai";
import {
  deleteBlock,
  getBlocks,
  patchBlock,
  patchBlocksReorder,
  postBlock,
} from "../services/blockService";

export const blockTypes = [
  {
    type: "TEXT",
    name: "PARAGRAPHE",
    text: "",
  },
  {
    type: "ANECDOTE",
    name: "ANECDOTE",
    text: "",
  },
  {
    type: "IMAGE",
    name: "IMAGE UNIQUE",
    files: [],
  },
  {
    type: "IMAGES",
    name: "IMAGES MULTIPLES",
    files: [],
  },
  {
    type: "VIDEO",
    name: "VIDÉO YOUTUBE",
    videoId: "",
    title: "",
    description: "",
  },
  {
    type: "QUIZZ",
    name: "QUIZZ",
    question: "",
    hint: "",
    answer1: "",
    answer2: "",
    answer3: "",
    goodAnswer: "",
  },
];
const blocksAtom = atom([]);
const isSavingBlocksAtom = atom([]);
const isAddingBlockAtom = atom(false);
const isSavingAllBlocksAtom = atom(false);

const useBlocks = () => {
  const [blocks, setBlocks] = useAtom(blocksAtom);
  const [isSavingBlocks, setIsSavingBlocks] = useAtom(isSavingBlocksAtom);
  const [isAddingBlock, setIsAddingBlock] = useAtom(isAddingBlockAtom);
  const [isSavingAllBlocks, setIsSavingAllBlocks] = useAtom(isSavingAllBlocksAtom);

  const onGetContentBlocks = (stepId) => {
    getBlocks(stepId).then(setBlocks);
  };

  const onupdateContentBlocks = (stepId, newBlocks) => {
    setBlocks(newBlocks);
    setIsSavingAllBlocks(true);
    const oldBlocks = blocks;
    patchBlocksReorder(stepId, newBlocks)
      .catch((err) => setBlocks(oldBlocks))
      .finally(() => setIsSavingAllBlocks(false));
  };

  const onAddContentBlock = (newblock) => {
    setIsAddingBlock(true);
    postBlock(newblock)
      .then((data) => {
        const newBlocks = [...blocks, data];
        setBlocks(newBlocks);
      })
      .finally(() => setIsAddingBlock(false));
  };

  const onUpdateContentBlock = (newBlock) => {
    setIsSavingBlocks([...isSavingBlocks, newBlock.id]);
    patchBlock(newBlock)
      .then((data) => {
        const newBlocks = [...blocks.map((block) => (block.id == newBlock.id ? newBlock : block))];
        setBlocks(newBlocks);
      })
      .finally(() => {
        setIsSavingBlocks([...isSavingBlocks.filter((b) => b.id == newBlock.id)]);
      });
  };

  const onRemoveContentBlock = (newBlock) => {
    setIsSavingBlocks([...isSavingBlocks, newBlock.id]);
    deleteBlock(newBlock.id)
      .then((data) => {
        const newBlocks = [...blocks.filter((block) => block.id != newBlock.id)];
        setBlocks(newBlocks);
      })
      .finally(() => {
        setIsSavingBlocks([...isSavingBlocks.filter((b) => b.id == newBlock.id)]);
      });
  };

  return {
    blocks,
    isSavingBlocks,
    isAddingBlock,
    isSavingAllBlocks,
    actions: {
      onGetContentBlocks,
      onupdateContentBlocks,
      onAddContentBlock,
      onUpdateContentBlock,
      onRemoveContentBlock,
    },
  };
};

export default useBlocks;
