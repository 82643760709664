import Page from "../../Common/Page/Page";

const IndexPage = () => {
  return (
    <Page title="Accueil">
      <p>Bientôt seront affichées les statistiques d'utilisation...</p>
    </Page>
  );
};

export default IndexPage;
