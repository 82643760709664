const LoadButton = ({ isLoading, ...props }) => {
  return (
    <button {...props} disabled={isLoading ? "disabled" : false}>
      {isLoading ? (
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="visually-hidden">...</span>
        </div>
      ) : (
        { ...props.children }
      )}
    </button>
  );
};

export default LoadButton;
