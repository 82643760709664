import { AiOutlineCheck } from "react-icons/ai";
import Control from "../../../../Common/Controls/Control";

const BlockItemQuizz = ({ block, onValueChange }) => {
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    onValueChange({ ...block, [name]: value });
  };

  const getAnswerLabel = (num) => {
    return num == block.goodAnswer ? (
      <>
        Réponse {num} <AiOutlineCheck className="text-success ms-1" />
      </>
    ) : (
      `Réponse ${num}`
    );
  };

  return (
    <div>
      <Control
        label="Question"
        type="text"
        name="question"
        value={block.question}
        change={handleChangeValue}
      />
      <Control
        label="Indice (facultatif)"
        type="text"
        name="hint"
        value={block.hint}
        change={handleChangeValue}
      />
      <Control
        label={getAnswerLabel("1")}
        type="text"
        name="answer1"
        value={block.answer1}
        change={handleChangeValue}
      />
      <Control
        label={getAnswerLabel("2")}
        type="text"
        name="answer2"
        value={block.answer2}
        change={handleChangeValue}
      />
      <Control
        label={getAnswerLabel("3")}
        type="text"
        name="answer3"
        value={block.answer3}
        change={handleChangeValue}
      />
      <Control
        label="Bonne réponse"
        type="btnList"
        name="goodAnswer"
        value={block.goodAnswer}
        change={handleChangeValue}
        datas={[
          { id: "1", name: "1" },
          { id: "2", name: "2" },
          { id: "3", name: "3" },
        ]}
        dataIndex="id"
        dataLabel="name"
        btnInline
      />
    </div>
  );
};

export default BlockItemQuizz;
