import styled from "styled-components";
import Img from "../../../Common/Img/Img";
import { IMAGE_URL } from "../../../config";

const ImageBlock = ({ data }) => {
  return (
    <div className="d-center flex-column w-100">
      <StyledImage src={IMAGE_URL + data.files[0].fileName} />
      <p className="mt-2">
        <i>{data.files[0].legend}</i>
      </p>
    </div>
  );
};

const StyledImage = styled(Img)`
  max-width: 100%;
  max-height: 300px;
`;

export default ImageBlock;
