import { Link } from "react-router-dom";
import styled from "styled-components";

const NavbarItem = ({ path, title, icon, isActive }) => {
  return (
    <li className="nav-item" key={`routelink${title}`}>
      <StyledNavLink
        to={path}
        className={`nav-link text-white d-flex align-items-center justify-content-start ${
          isActive ? "selected" : ""
        }`}
        aria-current="page"
      >
        {icon}
        <span className="ms-2">{title}</span>
      </StyledNavLink>
    </li>
  );
};

const StyledNavLink = styled(Link)`
  position: relative;

  &.selected {
    &::after {
      content: "";
      position: absolute;
      right: -22px;
      top: 0px;
      height: 100%;
      width: 3px;
      background: var(--bs-primary);
      border-radius: 10px;
    }
  }
`;

export default NavbarItem;
