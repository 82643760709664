import Control from "../../../../Common/Controls/Control";
import MemoedVideoPlayer from "../../../../Common/VideoPlayer/VideoPlayer";

const BlockItemVideo = ({ block, onValueChange }) => {
  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    onValueChange({ ...block, [name]: value });
  };

  return (
    <div className="row">
      <div className="col-12 col-md-6">
        <Control
          label="Id de la vidéo"
          placeholder="(ex : TGMYQEdNhSM)"
          type="text"
          name="videoId"
          value={block.videoId}
          change={handleChangeValue}
          info={
            <span className="text-black-50 ps-2">
              www.youtube.com/watch?v=
              <strong className="text-dark">6lplnpsqyRY</strong>
            </span>
          }
        />
        <Control
          label="Titre"
          type="text"
          name="title"
          value={block.title}
          change={handleChangeValue}
        />
        <Control
          label="Description"
          type="textarea"
          name="description"
          value={block.description}
          change={handleChangeValue}
        />
      </div>
      <div className="col-12 col-md-6">
        <MemoedVideoPlayer videoId={block.videoId} />
      </div>
    </div>
  );
};

export default BlockItemVideo;
