import { toast } from "react-toastify";
import axios from "axios";
import { DateTime } from "luxon";
import { blockTypes } from "../hooks/useBlocks";

export const tools = {
  setAuthToken: (token) => {
    if (token) {
      // Apply to every request
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      // Delete Auth header
      delete axios.defaults.headers.common["Authorization"];
    }
  },

  isEmpty: (value) => {
    return (
      value === undefined ||
      value === null ||
      !value ||
      (typeof value === "object" && Object.keys(value).length === 0) ||
      (typeof value === "string" && value.trim().length === 0)
    );
  },
  copyText: (ref) => {
    ref.current.select();
    ref.current.setSelectionRange(0, 99999);
    document.execCommand("copy");
  },
  truncate: (text, n) => {
    return text.length > n ? text.substr(0, n - 1) + "..." : text;
  },

  filterError: (errors, dispatch = null, type) => {
    var errorData = {};
    if (errors.response) {
      var status = errors.response.status;

      if (status == 404) {
        toast.error("La route n'a pas été trouvée");
      }

      if (errors.response.data) {
        if (errors.response.data.other) {
          toast.warning("Erreur : " + errors.response.data.other);
          errorData = errors.response.data;
        } else {
          errorData = errors.response.data;
        }
      }
    } else {
      toast.error("Erreur interne : " + errors);
    }

    if (dispatch) {
      dispatch({ type: type, payload: false });
      dispatch({ type: "GET_ERRORS", payload: errorData });
    }
    return errorData;
  },
  filterAlert: (type, message) => {
    switch (type) {
      case "success":
        toast.success(message);
        break;
      case "info":
        toast.info(message);
        break;

      default:
        break;
    }
  },
  getState: (page, initial) => {
    var ls = localStorage.getItem(page);

    if (ls) {
      return JSON.parse(ls);
    }
    return initial;
  },

  saveState: (page, name, value) => {
    var ls = localStorage.getItem(page);
    if (ls) {
      ls = JSON.parse(ls);
    } else {
      ls = {};
    }
    ls[name] = value;

    localStorage.setItem(page, JSON.stringify(ls));
  },

  isParentRoleActive(roleKey, roleConfig, dependsOn) {
    if (!dependsOn) {
      return true;
    }
    var isActive = true;
    dependsOn.forEach((d) => {
      if (!roleConfig[d][roleKey]) {
        isActive = false;
      }
    });
    return isActive;
  },
  validateEmail(mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
      return true;
    }

    return false;
  },
  validateTel(tel) {
    return tel.match(/^\d{10}$/);
  },
  getFullName(user) {
    return user
      ? (user.gender == 1 ? "Mr " : user.gender == 2 ? "Mme " : "") +
          user.firstname +
          " " +
          user.lastname
      : "";
  },
  getFullAddress(addr) {
    return addr.address + " - " + addr.location.postalCode + ", " + addr.location.name;
  },
  getFullDate(date) {
    return DateTime.fromISO(date).toFormat("dd/MM/yyyy");
  },
  round(num) {
    if (isNaN(parseFloat(num))) return "";
    return Math.round(parseFloat(num) * 100) / 100;
  },
  roundInt(num) {
    if (isNaN(parseFloat(num))) return "";
    return Math.round(parseFloat(num));
  },
  printDiv(element) {
    var printContents = document.querySelector(element).innerHTML;
    var originalContents = document.body.innerHTML;

    document.body.innerHTML = printContents;

    window.print();

    document.body.innerHTML = originalContents;
  },

  print: (printOrientation) => {
    var css = `@page { size: ${printOrientation}; } html,body,#root {background:white !important;}`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);

    window.print();
  },
  findDoublonsInArray: (yourArray) => {
    let duplicates = [];

    const tempArray = [...yourArray].sort();

    for (let i = 0; i < tempArray.length; i++) {
      if (tempArray[i + 1] === tempArray[i]) {
        duplicates.push(tempArray[i]);
      }
    }
    return duplicates;
  },
  arrayMove: (arr, old_index, new_index) => {
    let newArray = [...arr];
    if (new_index >= newArray.length) {
      var k = new_index - newArray.length + 1;
      while (k--) {
        newArray.push(undefined);
      }
    }
    newArray.splice(new_index, 0, newArray.splice(old_index, 1)[0]);
    return newArray;
  },
  getBlockNameFromType: (blockType) => {
    let btref = blockTypes.find((b) => b.type == blockType);

    return btref?.name || "";
  },
};
