import React, { useState } from "react";
import styled from "styled-components";

const QuizzBlock = ({ data }) => {
  const [answer, setAnswer] = useState("");

  const handleChangeAnswer = (value) => {
    setAnswer(value);
  };

  return (
    <div>
      <StyledQuestion className="fw-bold mb-3 fs-5">{data.question}</StyledQuestion>
      <ButtonsContainer className="d-flex flex-column text-white">
        <button
          className={`btn ${answer == 1 ? "btn-light" : "btn-outline-light"}`}
          onClick={() => handleChangeAnswer(1)}
        >
          {data.answer1}
        </button>
        <button
          className={`btn ${answer == 2 ? "btn-light" : "btn-outline-light"}`}
          onClick={() => handleChangeAnswer(2)}
        >
          {data.answer2}
        </button>
        <button
          className={`btn ${answer == 3 ? "btn-light" : "btn-outline-light"}`}
          onClick={() => handleChangeAnswer(3)}
        >
          {data.answer3}
        </button>
      </ButtonsContainer>

      {answer && (
        <div>
          {answer == data.goodAnswer ? (
            <div className="alert alert-success">Bonne réponse !</div>
          ) : (
            <div className="alert alert-danger">Mauvaise réponse</div>
          )}
        </div>
      )}
    </div>
  );
};

const StyledQuestion = styled.div`
  color: var(--pink-color);
`;

const ButtonsContainer = styled.div`
  & .btn {
    margin-bottom: 5px;
  }
`;

export default QuizzBlock;
