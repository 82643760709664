import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";
import useAuth from "../../hooks/useAuth";
import NavbarItem from "./NavbarItem";

const Navbar = ({ routes }) => {
  const { isAuth, actions } = useAuth();
  const location = useLocation();

  return !isAuth ? null : (
    <StyledNav className="nav-side d-flex flex-column flex-shrink-0 p-3 bg-primary border-end text-white">
      <Link to="/" className="d-center text-white text-decoration-none w-100">
        <span className="fs-4">LAZARET</span>
      </Link>
      <button onClick={actions.logoutUser} className="btn btn-link text-light d-center w-100">
        Se déconnecter
      </button>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        {routes
          .filter((route) => route.inNavbar)
          .map((route) => (
            <NavbarItem
              key={`navbaritem${route.title}`}
              isActive={location.pathname == route.path}
              {...route}
            />
          ))}
      </ul>
    </StyledNav>
  );
};

const StyledNav = styled.div`
  width: 280px;
  min-height: 100vh;
`;

export default Navbar;

{
  /* <nav className="nav-top navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand" href="#">
            LAZARET
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {routes
                .filter((route) => route.inNavbar)
                .map((route) => (
                  <li className="nav-item" key={`routelink${route.title}`}>
                    <Link
                      to={route.path}
                      className={`nav-link ${location.pathname == route.path ? "active" : ""}`}
                      aria-current="page"
                    >
                      {route.title}
                    </Link>
                  </li>
                ))}
            </ul>
          </div>
        </div>
      </nav> */
}
