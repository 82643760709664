import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import Loader from "../Loader/Loader";
import { motion } from "framer-motion";

const Page = ({ title, isLoading, back = null, children }) => {
  const pageVariants = {
    initial: {
      opacity: 0,
      y: 50,
    },
    in: {
      opacity: 1,
      y: 0,
    },
    out: {
      opacity: 0,
      y: -50,
    },
  };

  return (
    <motion.div
      className="px-5 my-5"
      initial="initial"
      exit="out"
      animate="in"
      variants={pageVariants}
      transition={{ duration: 0.3 }}
    >
      <h1 className="mb-4">
        {back && (
          <Link to={back} className="me-2">
            <IoMdArrowBack />
          </Link>
        )}
        {title}
      </h1>
      {isLoading ? <Loader /> : <div>{children}</div>}
    </motion.div>
  );
};

export default Page;
