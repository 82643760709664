import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Container from "../../Common/Container/Container";
import Control from "../../Common/Controls/Control";
import LoadButton from "../../Common/LoadButton/LoadButton";
import Page from "../../Common/Page/Page";
import useManagers from "../../hooks/useManagers";
import { tools } from "../../utils/tools";
import ModifyPassword from "./ModifyPassword";

const ManagersDetailPage = () => {
  const { manager, actions, managerErrors, isLoadingManager, isSavingManager } = useManagers();
  const { id } = useParams();

  useEffect(() => {
    if (id !== "create") {
      actions.getManager(id);
    } else {
      actions.handleResetManager();
    }
  }, [id]);

  const saveManager = () => {
    if (!manager.id) {
      actions.postManager(manager);
    } else {
      actions.patchManager(manager);
    }
  };

  return (
    <Page
      title={manager.id ? tools.getFullName(manager) : "Créer un administrateur"}
      isLoading={isLoadingManager}
      back="/managers"
    >
      <Container>
        <div className="row">
          <div className="col-12">
            <Control
              type="text"
              label="Nom"
              name="lastname"
              value={manager.lastname}
              change={actions.handleChangeManagerData}
              error={managerErrors}
            />
            <Control
              type="text"
              label="Prénom"
              name="firstname"
              value={manager.firstname}
              change={actions.handleChangeManagerData}
              error={managerErrors}
            />
            <Control
              type="email"
              label="Email"
              name="email"
              value={manager.email}
              change={actions.handleChangeManagerData}
              error={managerErrors}
            />
            {!manager.id && (
              <Control
                type="password"
                label={`Mot de passe`}
                name="password"
                value={manager.password}
                change={actions.handleChangeManagerData}
                error={managerErrors}
              />
            )}
          </div>
          <div className="col-12">
            <LoadButton
              className="btn btn-success"
              isLoading={isSavingManager}
              onClick={saveManager}
            >
              <span>{!manager.id ? "Créer" : "Modifier"}</span>
            </LoadButton>
          </div>
          {manager.id && (
            <div className="col-12 mt-5">
              <ModifyPassword />
            </div>
          )}
        </div>
      </Container>
    </Page>
  );
};

export default ManagersDetailPage;
