import { useEffect } from "react";
import { Link } from "react-router-dom";
import Fab from "../../Common/Fab/Fab";
import Page from "../../Common/Page/Page";
import Table from "../../Common/Table/Table";
import useManagers from "../../hooks/useManagers";
import { tools } from "../../utils/tools";
import { AiOutlinePlus } from "react-icons/ai";
import Container from "../../Common/Container/Container";

const ManagersPage = () => {
  const { managers, actions, isLoadingManagers } = useManagers();

  useEffect(() => {
    actions.getManagers();
  }, []);

  return (
    <Page title="Administrateurs">
      <Container>
        <Table
          isLoading={isLoadingManagers}
          datas={managers}
          nbResults={managers.length}
          fields={[
            {
              path: "*",
              name: "Nom",
              render: (el) => tools.getFullName(el),
            },
            {
              path: "email",
              name: "Email",
            },
            {
              path: "id",
              name: "Actions",
              render: (el) => (
                <div className="btn-group">
                  <Link to={`/managers/${el}`} className="btn btn-primary btn-sm">
                    Modifier
                  </Link>
                  <button
                    onClick={() => actions.removeManager(el)}
                    className="btn btn-danger btn-sm"
                  >
                    Supprimer
                  </button>
                </div>
              ),
            },
          ]}
        />
      </Container>
      <Fab>
        <Link to="/managers/create" className="btn btn-primary rounded-circle">
          <AiOutlinePlus />
        </Link>
      </Fab>
    </Page>
  );
};

export default ManagersPage;
