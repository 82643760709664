import React, { useState } from "react";
import FileUpload from "../../../../Common/FileInput/FileUpload";
import ImageSlider from "../../../../Common/ImageSlider/ImageSlider";
import { deleteFile, postFiles } from "../../../../services/fileService";

const BlockItemImages = ({ block, onValueChange }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDeletingFileId, setIsDeletingFileId] = useState(false);

  const onFilesChange = (newFiles) => {
    //upload file
    if (newFiles?.length > 0) {
      setIsUploading(true);
      postFiles(newFiles)
        .then((data) => {
          handleChangeValue({
            target: {
              name: "files",
              value: [...block.files, ...data.map((file) => ({ ...file, legend: "" }))],
            },
          });
        })
        .finally(() => setIsUploading(false));
    }
  };

  const onFileDelete = (fileId) => {
    setIsDeletingFileId(fileId);
    deleteFile(fileId)
      .then((data) => {
        handleChangeValue({
          target: {
            name: "files",
            value: block.files.filter((f) => f.id !== fileId),
          },
        });
      })
      .finally(() => setIsDeletingFileId(false));
  };

  const handleChangeValue = (e) => {
    const { name, value } = e.target;
    onValueChange({ ...block, [name]: value });
  };

  const handleChangeLegend = (e, fileId) => {
    let newFile = block.files.find((f) => f.id == fileId);
    newFile = { ...newFile, legend: e.target.value };
    const newFiles = block.files.map((f) => (f.id == fileId ? newFile : f));
    handleChangeValue({ target: { name: "files", value: newFiles } });
  };

  const onFileChangeOrder = (sourceIndex, destinationIndex) => {
    const items = Array.from(block.files);
    const [reorderedItems] = items.splice(sourceIndex, 1);
    items.splice(destinationIndex, 0, reorderedItems);
    handleChangeValue({ target: { name: "files", value: items } });
  };

  return (
    <div>
      <FileUpload onFileDrop={onFilesChange} isLoading={isUploading} />
      {block.files.length > 0 && (
        <>
          <ImageSlider
            images={block.files}
            onChangeLegend={handleChangeLegend}
            onFileDelete={onFileDelete}
            onFileChangeOrder={onFileChangeOrder}
            isDeletingFileId={isDeletingFileId}
          />
        </>
      )}
    </div>
  );
};

export default BlockItemImages;
