import { AnimatePresence } from "framer-motion";
import { Route, Routes, useLocation } from "react-router-dom";
import PrivateRoute from "./Common/PrivateRoute/PrivateRoute";
import { routesConfig } from "./routesConfig";

const AppRoutes = () => {
  const location = useLocation();

  return (
    <AnimatePresence exitBeforeEnter>
      <Routes location={location} key={location.pathname}>
        {routesConfig.map((route) => (
          <Route
            key={`route${route.title}`}
            path={route.path}
            element={
              route.type == "private" ? <PrivateRoute>{route.element}</PrivateRoute> : route.element
            }
          />
        ))}
      </Routes>
    </AnimatePresence>
  );
};

export default AppRoutes;
