import React from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import styled from "styled-components";
import Loader from "../Loader/Loader";

const FileUpload = ({ onFileDrop, isSingleFile, isLoading }) => {
  const onDrop = (files) => {
    // Do something with the files
    const authorizedFormats = ["image/jpeg", "image/png"];
    if (files.length > 0) {
      let hasErrors = false;
      Array.from(files).forEach((file) => {
        if (!authorizedFormats.includes(file.type)) {
          toast.error(`${file.name} n'a pas le bon format (jpg, jpeg ou png)`);
          hasErrors = true;
        }
      });
      if (!hasErrors) {
        onFileDrop(isSingleFile ? [files[0]] : files);
      }
    } else {
      toast.error("Aucun fichier valide n'a été choisi");
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
  });

  return (
    <StyledFileUploadSingle
      className="d-center text-center p-3 w-100 text-black-50 cursor-pointer"
      {...getRootProps()}
    >
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Déposez le fichier ici ...</p>
          ) : (
            <div>
              Glissez-déposez des fichiers <br />
              ou cliquez pour parcourir vos dossiers
            </div>
          )}
        </>
      )}
    </StyledFileUploadSingle>
  );
};

const StyledFileUploadSingle = styled.div`
  height: 100%;
  border: 1px dashed grey;
  background: var(--bs-gray-100);
`;

export default FileUpload;
