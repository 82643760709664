const ErrorsDisplay = ({ errorsObject, excludedKeys = [] }) => {
  const errorsArray = Object.keys(errorsObject);

  if (errorsArray.length == 0) return null;

  console.log("errorsArray", errorsArray);

  return (
    errorsArray.length > 0 &&
    errorsArray
      .filter((errKey) => !excludedKeys.includes(errKey))
      .map((errKey) => <div className="alert alert-danger p-1 mb-1">{errorsObject[errKey]}</div>)
  );
};

export default ErrorsDisplay;
