import dataService from "../utils/dataService";

export const getConstants = () => {
  return new Promise((resolve, reject) => {
    dataService.get({
      url: `constants`,
      onSuccess: resolve,
      onError: reject,
    });
  });
};
