import { atom, useAtom } from "jotai";
import { deleteStep, getSteps, patchReorderSteps } from "../services/stepService";

const stepsAtom = atom([]);
const isLoadingStepsAtom = atom(false);
const isDeletingStepAtom = atom(false);
const isReorderingStepsAtom = atom(false);

const useSteps = () => {
  const [steps, setSteps] = useAtom(stepsAtom);
  const [isLoadingSteps, setIsLoadingSteps] = useAtom(isLoadingStepsAtom);
  const [isDeletingStep, setIsDeletingStep] = useAtom(isDeletingStepAtom);
  const [isReorderingSteps, setIsReorderingSteps] = useAtom(isReorderingStepsAtom);

  const onGetSteps = () => {
    setIsLoadingSteps(true);
    getSteps()
      .then(setSteps)
      .finally(() => setIsLoadingSteps(false));
  };

  const onDeleteStep = (stepId) => {
    setIsDeletingStep(stepId);
    if (window.confirm("Confirmez-vous la suppression de cette étape ?")) {
      deleteStep(stepId)
        .then((data) => {
          setSteps(steps.filter((s) => s.id !== stepId));
        })
        .finally(() => setIsDeletingStep(false));
    }
  };

  const onReorderSteps = (newSteps) => {
    setSteps(newSteps);
    setIsReorderingSteps(true);
    patchReorderSteps(newSteps).finally(() => setIsReorderingSteps(false));
  };

  return {
    steps,
    isLoadingSteps,
    isReorderingSteps,
    actions: {
      onGetSteps,
      onDeleteStep,
      onReorderSteps,
    },
  };
};

export default useSteps;
