import styled from "styled-components";

export const SaveIndicatorContainer = ({ children }) => (
  <StyledSaveIndicatorContainer className="d-center shadow">
    {children}
  </StyledSaveIndicatorContainer>
);

const StyledSaveIndicatorContainer = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 40px;
  height: 40px;
  background: white;
  border-bottom-left-radius: 20px;
`;
