import React from "react";
import ReactDOM from "react-dom";
import "./theme/variables.scss";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import { IconContext } from "react-icons";
import "animate.css";

ReactDOM.render(
  <React.StrictMode>
    <IconContext.Provider value={{ style: { verticalAlign: "middle" } }}>
      <App />
    </IconContext.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
