import styled from "styled-components";
import Control from "../../Common/Controls/Control";
import ErrorAlert from "../../Common/ErrorAlert/ErrorAlert";
import LoadButton from "../../Common/LoadButton/LoadButton";
import useLoginPage from "./useLoginPage";

const LoginPage = () => {
  const { user, errors, isLoading, actions } = useLoginPage();

  return (
    <div className="w-100 h-100 d-center">
      <StyledRow className="row">
        <div className="col-12">
          <form onSubmit={actions.onSubmit}>
            <div className="card shadow-lg">
              <div className="card-header bg-white text-center border-0 fs-4 py-4">
                Espace Administrateur
              </div>
              <div className="card-body">
                <Control
                  label="Email"
                  type="email"
                  name="login"
                  value={user.login}
                  change={actions.handleChangeInput}
                  error={errors}
                />
                <Control
                  label="Mot de passe"
                  type="password"
                  name="password"
                  value={user.password}
                  change={actions.handleChangeInput}
                  error={errors}
                />
              </div>
              <div className="card-footer bg-primary p-0">
                <div className="d-center flex-column">
                  {errors.other && <ErrorAlert>{errors.other}</ErrorAlert>}
                  <LoadButton
                    className="btn btn-primary py-3 w-100"
                    type="submit"
                    isLoading={isLoading}
                  >
                    <>Se connecter</>
                  </LoadButton>
                </div>
              </div>
            </div>
          </form>
        </div>
      </StyledRow>
    </div>
  );
};

const StyledRow = styled.div`
  width: min(80%, 400px);
`;

export default LoginPage;
