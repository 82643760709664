import IndexPage from "./Pages/IndexPage/IndexPage";
import ManagersPage from "./Pages/ManagersPage/ManagersPage";
import VisitPage from "./Pages/VisitPage/VisitPage";
import { RiAdminFill } from "react-icons/ri";
import { AiTwotoneHome, AiOutlineQrcode } from "react-icons/ai";
import { BsFillSignpostSplitFill } from "react-icons/bs";
import ManagersDetailPage from "./Pages/ManagersDetailPage/ManagersDetailPage";
import VisitDetailPage from "./Pages/VisitDetailPage/VisitDetailPage";
import QRCodePage from "./Pages/QRCodePage/QRCodePage";

export const routesConfig = [
  {
    type: "private",
    path: "/",
    title: "Accueil",
    inNavbar: true,
    element: <IndexPage />,
    icon: <AiTwotoneHome />,
  },
  {
    type: "private",
    path: "/managers",
    title: "Administrateurs",
    inNavbar: true,
    element: <ManagersPage />,
    icon: <RiAdminFill />,
  },
  {
    type: "private",
    path: "/managers/:id",
    title: "Détail d'un administrateur",
    inNavbar: false,
    element: <ManagersDetailPage />,
    icon: <BsFillSignpostSplitFill />,
  },
  {
    type: "private",
    path: "/visit",
    title: "Visite guidée",
    inNavbar: true,
    element: <VisitPage />,
    icon: <BsFillSignpostSplitFill />,
  },
  {
    type: "private",
    path: "/visit/:id",
    title: "Visite guidée",
    inNavbar: false,
    element: <VisitDetailPage />,
    icon: <BsFillSignpostSplitFill />,
  },
  {
    type: "private",
    path: "/qrcodes",
    title: "QR codes",
    inNavbar: true,
    element: <QRCodePage />,
    icon: <AiOutlineQrcode />,
  },
];
